import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not found" />
      <div className="container_404">
        <h1 data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease">
          404
        </h1>
        <p data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease">
          Página No Encontrada.
        </p>
        <Link to="/">
          <button
            className="btn btn__primary m10 "
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Volver
          </button>
        </Link>
      </div>
    </>
  )
}

export default NotFoundPage
